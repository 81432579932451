import React from "react";
import { Link } from "gatsby";

type ViewMoreButtonProps = {
  href: string;
  isPrimary?: boolean;
  text?: string;
  isExterior?: boolean;
  isAnimation?: boolean;
};
export const ViewMoreButton: React.FC<ViewMoreButtonProps> = (props) => {
  const {
    href,
    isPrimary,
    text,
    isExterior = false,
    isAnimation = true,
  } = props;

  return (
    <div
      data-page-animation={isAnimation ? "true" : "done"}
      data-type-fade={true}
    >
      {!isExterior ? (
        <Link
          to={href}
          className={`flex justify-center flex-none items-center w-[160px] md:w-[180px] h-[50px]
                text-[12px] md:text-[14px] font-bold
                ${isPrimary ? "bg-primary" : "bg-green"}
                text-white text-sm tracking-[0.12em] antialiased
                rounded-xs mx-auto hover:opacity-75 transition-opacity duration-300`}
        >
          {text ?? "VIEW MORE"}
        </Link>
      ) : (
        <a
          href={href}
          target="_blank"
          rel="noreferrer"
          className={`flex justify-center flex-none items-center w-[160px] md:w-[180px] h-[50px]
                text-[12px] md:text-[14px] font-bold
                ${isPrimary ? "bg-primary" : "bg-green"}
                text-white text-sm tracking-[0.12em] antialiased
                rounded-xs mx-auto hover:opacity-75 transition-opacity duration-300
                pseudo-after after:bg-open-external-link
                after:w-[10px] md:after:w-[12px] after:h-[10px] md:after:h-[12px]
                after:inline-block after:ml-2`}
        >
          {text ?? "VIEW MORE"}
        </a>
      )}
    </div>
  );
};
