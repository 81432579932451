import React from "react";
import { BaseLayout } from "../components/views/layouts/BaseLayout";
import { ViewMoreButton } from "../components/parts/ViewMoreButton";
import { BcItem } from "../components/views/Breadcrumb";

const bcList: BcItem[] = [{ href: "", text: "お探しのページが見つかりません" }];

const NotFoundPage = () => {
  return (
    <BaseLayout
      bcList={bcList}
      showOnlineShopButton={false}
      pageUrl=""
      pageTitle="お探しのページが見つかりません"
      pageDescription=""
      isNoIndexPage={true}
    >
      <div className="pt-[100px] md:pt-[180px] px-5">
        <h1
          className="text-xl md:text-[28px] tracking-[0.1em]
          md:tracking-[.12em] font-bold text-center mb-5 md:mb-[74px] antialiased"
        >
          お探しのページが見つかりません
        </h1>
        <p className="text-center mb-10 md:mb-[74px]">
          お探しのページが見つかりませんでした。
        </p>
        <ViewMoreButton href="/" text="TOPへ" isPrimary={true} />
      </div>
    </BaseLayout>
  );
};

export default NotFoundPage;
